import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { alleColors } from '@packages/deprecated-component-library/src/colorPalette';
import { Typography } from '@packages/deprecated-universal-component-library/src/atoms/typography';
import { LinearProgressBar } from '@packages/deprecated-consumer-component-library/src/linearProgress/linearProgressBar';
import { AlistTrackerContent } from '@packages/deprecated-universal-component-library/src/helpers/alistTier/alistTrackerContent';
import { logger } from '@packages/core-datadog-logging/src/logger';
import { TierAListTypes } from '@packages/deprecated-universal-component-library/src/helpers/alistTier/alistTypes';
import { above } from '@packages/deprecated-universal-component-library/src/utils/mediaTemplate';
import { Maybe } from '@packages/core-alle-graphql-types';
import { TrackerIcon } from '@packages/deprecated-component-library/src/icons/alistTrackerIcon';
import { Dialog } from '@packages/deprecated-component-library/src/components/dialog';

import { AlistPerks } from '@packages/deprecated-consumer-component-library/src/tier/alistPerks';
import { AlistTrackerFooter } from '@packages/deprecated-consumer-component-library/src/tier/alistTrackerFooter';
import { AlistTrackerSkeletonLoader } from './alistSkeletonLoader';

const AlistModal = styled.div<{ themecolor?: string }>`
  position: relative;
  background: ${(props) => props.themecolor};
  height: 100%;
  width: 100%;
  max-width: 767px;
  overflow: auto;

  ${above.tablet`
    width: 510px;
    height: 763px;
    border-radius: 8px;
    border: 1px solid #E5E0DD;
    box-shadow: 0px 16px 24px ${alleColors.black}1F;
    overflow-y: scroll
 `}
`;

const ModalHeader = styled.div<{ themecolor: string }>`
  position: relative;
  width: 100%;
  height: 306px;
  background: ${(props) => props.themecolor};

  & > button {
    position: absolute;
    right: 8px;
    top: 16px;
  }

  ${above.tablet`
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
 `}
`;

const TrackerHeader = styled.div`
  height: 222px;
  width: 100%;
  position: absolute;
  top: 64px;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const ProgressContainer = styled.div`
  width: 272px;
  max-width: 100%;
  margin: 24px 0;
`;

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
`;

const StyledDialog = styled(Dialog)`
  &.MuiDialog-root {
    min-height: 100%;
    .MuiDialog-container {
      .MuiPaper-root {
        display: inline-block;
        min-width: 100vw;
        width: 100vw;
        margin: 0;
        border-radius: 0;
        background: ${alleColors.white}80;
      }
    }
  }
`;

const StyledIconButton = styled(IconButton)<{ iconcolor: string }>`
  &.MuiIconButton-colorInherit {
    color: ${(props) => props.iconcolor};
  }
`;

export interface AlistTrackerProps {
  open: boolean;
  onClose: () => void;
  type: TierAListTypes;
  points: Maybe<number> | undefined;
  tierThreshold?: number | null;
  computedTierPoints: number;
  error?: boolean | undefined;
  loading?: boolean;
  isRewardsHubEnabled: boolean;
}
export const AlistTrackerDialog = (props: AlistTrackerProps) => {
  const {
    open,
    onClose,
    type,
    points,
    tierThreshold,
    computedTierPoints,
    loading,
    error,
    isRewardsHubEnabled,
  } = props;
  const handleClose = () => {
    onClose();
  };

  const {
    title,
    titleColor,
    message,
    messageColor,
    progressTextColor,
    iconColor,
    theme,
    progressBackground,
    progressColor,
    perksTheme,
  } = AlistTrackerContent(type, points, tierThreshold ?? 1200);

  if (error) {
    logger.error('AlistTrackerDetails--Error', {
      error: 'Unable to load Alist Tracker details',
    });
  }

  return (
    <StyledDialog
      disableEnforceFocus
      fullScreen
      aria-labelledby="alist-tracker-dialog"
      open={open}
      data-testid={'alist-tracker-modal'}
    >
      <ModalWrapper>
        <AlistModal themecolor={perksTheme}>
          <ModalHeader themecolor={theme}>
            <TrackerHeader>
              <TrackerIcon type={type} />
              {loading ? (
                <AlistTrackerSkeletonLoader
                  backgroundColor={`${progressBackground}`}
                />
              ) : (
                <>
                  <ProgressContainer>
                    <LinearProgressBar
                      progresscolor={progressColor}
                      progressbackground={progressBackground}
                      textcolor={progressTextColor}
                      value={computedTierPoints}
                    />
                  </ProgressContainer>

                  <Typography
                    data-testid={'alist-tracker-title'}
                    preset={{
                      s: 'body-L-regular',
                      l: 'body-L-regular',
                    }}
                    css={`
                      font-weight: bold;
                      width: 342px;
                      max-width: 100%;
                      ${above.tablet`
                       width: 432px;`}
                      color: ${titleColor}
                    `}
                  >
                    {title}
                  </Typography>
                  <Typography
                    data-testid={'alist-tracker-message'}
                    preset={{
                      s: 'body-L-regular',
                      l: 'body-L-regular',
                    }}
                    css={`
                      width: 342px;
                      max-width: 100%;
                      ${above.tablet`
                       width: 432px;
                     `}
                      color: ${messageColor}
                    `}
                  >
                    {message}
                  </Typography>
                </>
              )}
            </TrackerHeader>
            <StyledIconButton
              iconcolor={iconColor}
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              data-testid={'alist-tracker-close-icon'}
            >
              <CloseIcon />
            </StyledIconButton>
          </ModalHeader>
          <AlistPerks
            isAlister={type !== 'MEMBER'}
            isRewardsHubEnabled={isRewardsHubEnabled}
          />
          <AlistTrackerFooter isAlister={type !== 'MEMBER'} />
        </AlistModal>
      </ModalWrapper>
    </StyledDialog>
  );
};
