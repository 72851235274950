import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Box } from '@allergan-data-labs/alle-elements-layout';
import { NavigationDrawer } from '@allergan-data-labs/alle-elements-nav-drawer';
import { useColorMode } from '@allergan-data-labs/alle-elements-core';
import { Button, ButtonGroup } from '@allergan-data-labs/alle-elements-button';
import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';
import { NavigationItem } from './headerDrawerItem';
import { useHeaderTrackingEvents } from './useHeaderTrackingEvents';
import { useHeaderNavLinks } from './utils';

const initialDrawerItemsState: Record<string, boolean> = {
  brands: false,
  rewards: false,
};

type HeaderDrawerProps = {
  isOpen?: boolean;
  isLoggedIn?: boolean;
  currentPath: string;
  toggleDrawer: () => void;
  getLoginLinkProps: () => {
    href: string;
    referrerData?: { from: string | object };
  };
  getJoinLinkProps: () => { href: string };
};

const HeaderDrawer = ({
  isOpen,
  currentPath,
  isLoggedIn,
  toggleDrawer,
  getLoginLinkProps,
  getJoinLinkProps,
}: HeaderDrawerProps) => {
  const { location } = useHistory();
  const { colorMode } = useColorMode();
  const { Link } = useEnvironmentContext();
  const { trackLoginLinkClick, trackJoinAlleClick } = useHeaderTrackingEvents();

  const { links } = useHeaderNavLinks({
    isLoggedIn,
    currentPath: location.pathname,
  });

  const [drawerItemsState, setDrawerItemsState] = useState(
    initialDrawerItemsState
  );

  const toggleNavigationItem = (id: string) => {
    setDrawerItemsState({
      ...drawerItemsState,
      [id]: !drawerItemsState[id],
    });
  };

  return (
    <Box
      zIndex={1}
      sx={{
        '> div': {
          zIndex: 1,
        },
        section: {
          height: `calc(100vh - 64px)`,
          width: {
            base: '100vw',
            sm: 360,
          },
        },
      }}
    >
      {isOpen && (
        <Box
          display={{
            base: 'none',
            sm: 'block',
            md: 'none',
          }}
          position="fixed"
          backgroundColor="light.Container/Neutral/Inverted 40"
          width="100vw"
          height="100vh"
          pointerEvents="none"
          opacity={1}
          transition="opacity .25s"
        />
      )}
      <NavigationDrawer
        isOpen={isOpen}
        colorMode={colorMode}
        // This is a temp patch while we migrate to fully owned chakra components for this in https://adl-technology.atlassian.net/browse/PD-5435?atlOrigin=eyJpIjoiZWE1NmE1ODE2YzdmNGM1YzkxZDQ2NjUzNzY0ZTcwMGYiLCJwIjoiamlyYS1zbGFjay1pbnQifQ
        // The chakra drawer under the hood doesn't seem to work nicely with the route changes from react-router-link
        // So it doesn't close when switching between one level deep routes (for example going from /about to /our-brands)
        // Below is a hack to rebuild this component on each route change, not ideal but shouldn't have any noticeable impact on performance either.
        key={`header-nav-drawer-${currentPath}`}
      >
        {links.map((item) => (
          <NavigationItem
            key={item.id}
            item={item}
            isNestedOpen={drawerItemsState[item.id as string]}
            handleNestedToggle={() => toggleNavigationItem(item.id as string)}
            handleItemClick={toggleDrawer}
          />
        ))}
        {!isLoggedIn && (
          <ButtonGroup alignItems="center" gap={8} marginTop={'auto'}>
            <Link
              onClick={trackLoginLinkClick}
              {...getLoginLinkProps()}
              flex={1}
            >
              <Button size="sm" variant="outline" width="100%">
                Log In
              </Button>
            </Link>
            <Link
              onClick={trackJoinAlleClick}
              {...getJoinLinkProps()}
              flex={1}
              marginInlineStart={`0 !important`}
            >
              <Button size="sm" width="100%">
                Join Allē
              </Button>
            </Link>
          </ButtonGroup>
        )}
      </NavigationDrawer>
    </Box>
  );
};

export { HeaderDrawer };
