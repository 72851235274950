import { crFeatureKeys } from '@packages/core-split-feature-flag/src/consumer';
import { useAlleTreatments } from '@packages/core-split-feature-flag/src/useAlleTreatments';
import { useAuth } from '../auth/AuthProvider';

export const useProfileRedesignFeature = () => {
  const { currentUser } = useAuth();
  const splitTreatments = useAlleTreatments({
    splitNames: [crFeatureKeys.profileRedesign],
    key: currentUser?.id || undefined,
    attributes: {
      alle_id: currentUser?.id ?? '',
    },
  });
  const isProfileRedesignEnabled =
    splitTreatments[crFeatureKeys.profileRedesign]?.treatment === 'on';

  return isProfileRedesignEnabled;
};
