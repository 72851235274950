import React, { useEffect, useRef } from 'react';
import {
  ButtonSelect,
  ButtonSelectItem,
} from '@allergan-data-labs/alle-elements-button';
import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';
import { Box } from '@allergan-data-labs/alle-elements-layout';
import { NavigationItemLinks } from './headerDrawerItem';
import { useHeaderColors } from './useHeaderColors';

type HeaderSelectProps = NavigationItemLinks & {
  currentPath?: string;
  isLoggedIn?: boolean;
  hideOnScroll?: boolean;
};

const HeaderSelect = ({
  id,
  currentPath,
  title,
  items,
  hideOnLoggedInNav,
  isLoggedIn,
  hideOnScroll,
}: HeaderSelectProps) => {
  const buttonSelectRef = useRef<HTMLDivElement>(null);
  const btn = buttonSelectRef.current?.querySelector('button');

  const { Link } = useEnvironmentContext();
  const { navLinkColor, navLinkHoverColor } = useHeaderColors({
    isLoggedIn,
  });

  useEffect(() => {
    // Programmatically close dropdown when scroll trigger is true (same trigger that hides the entire nav bar)
    if (hideOnScroll && btn?.getAttribute('aria-expanded') === 'true') {
      btn?.click();
    }
  }, [btn, hideOnScroll]);

  if (isLoggedIn && hideOnLoggedInNav) {
    return null;
  }

  return (
    <Box
      sx={{
        '> div > button': {
          padding: 0,
          height: '32px',
          color: navLinkColor,
          backgroundColor: 'transparent',
          fontSize: '4',
          div: {
            gap: 2,
          },
          _hover: {
            '&:not(.static)': {
              backgroundColor: 'transparent',
              color: `${navLinkHoverColor}`,
            },
          },
          _active: {
            '&:not(.static)': {
              backgroundColor: 'transparent',
              _hover: {
                backgroundColor: 'transparent',
              },
            },
          },
        },
        'div[role=menu]': {
          maxHeight: 'unset',
        },
      }}
    >
      <ButtonSelect
        ref={buttonSelectRef}
        label={title}
        buttonProps={{
          colorScheme: 'muted',
          variant: 'ghost',
        }}
        // This is a temp patch while we migrate to fully owned chakra components for this in https://adl-technology.atlassian.net/browse/PD-5435?atlOrigin=eyJpIjoiZWE1NmE1ODE2YzdmNGM1YzkxZDQ2NjUzNzY0ZTcwMGYiLCJwIjoiamlyYS1zbGFjay1pbnQifQ
        // The chakra action menu under the hood doesn't seem to work nicely with the route changes from react-router-link
        // So it doesn't close when switching between one level deep routes (for example going from /about to /our-brands)
        // Below is a hack to rebuild this component on each route change, not ideal but shouldn't have any noticeable impact on performance either.
        key={`header-nav-select-${id}-${currentPath}`}
      >
        {items?.map((item) => {
          return (
            <ButtonSelectItem key={item.id}>
              <Link
                href={item.href}
                data-testid={`header-nav-${item.id}`}
                fontSize="14px"
                fontWeight={item.isActive ? '600' : '400'}
                maxWidth="234px"
                padding={1}
                isStandalone
                variant="action"
                _hover={{
                  textDecoration: 'none',
                }}
              >
                {item.title}
              </Link>
            </ButtonSelectItem>
          );
        }) || []}
      </ButtonSelect>
    </Box>
  );
};

export { HeaderSelect };
