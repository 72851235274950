import React, { PropsWithChildren } from 'react';
import { Box, SystemStyleObject } from '@chakra-ui/react';
import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';
import { LinkProps } from '@packages/core-components/src/components/environment-context/types';
import {
  ColorMode,
  getTypographyToken,
  useColorMode,
} from '@allergan-data-labs/alle-elements-core';

export const getNavigationDrawerLinkStyles = (
  colorMode?: ColorMode,
  isActive?: boolean
): SystemStyleObject => ({
  w: '100%',
  h: 'auto',
  py: 8,
  px: 12,
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  ...getTypographyToken('Body/Small/Regular'),
  textDecoration: 'none',
  color: `${colorMode}.Text/Neutral/Default`,
  bg: `${colorMode}.Container/Brand/Navlink/Enable`,
  fontSize: 7,
  fontWeight: isActive ? 'Semibold' : 'Normal',
  _hover: {
    color: `${colorMode}.Text/Neutral/Support`,
  },
  _active: {
    color: `${colorMode}.Text/Neutral/Support`,
    fontWeight: 'Normal',
  },
});

export const getNavigationDrawerLinkContainerStyles =
  (): SystemStyleObject => ({
    h: 'auto',
    mr: 'auto',
    display: 'flex',
    alignItems: 'center',
  });

export const getNavigationDrawerIndicatorStyles = (): SystemStyleObject => ({
  mr: -1,
});

export const getNavigationDrawerLinkIconStyles = (): SystemStyleObject => ({
  w: 20,
  h: 20,
});

export const getNavigationDrawerLinkFlexStyles = (): SystemStyleObject => ({
  mr: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const getNavigationDrawerIconStyles = (
  isOpen: boolean
): SystemStyleObject => {
  const styles: SystemStyleObject = {
    transition: 'transform 0.2s',
    transformOrigin: 'center',
  };

  if (isOpen) {
    styles.transform = 'rotate(-180deg)';
  }

  return styles;
};

export interface NavigationDrawerLinkItemProps
  extends Omit<LinkProps, 'children'> {
  /**
   * The left icon to be displayed on the link item.
   */
  icon?: React.ReactElement;
  /**
   * The title or label of the link item.
   */
  title?: string;
  /**
   * Custom render function for the title.
   */
  renderTitle?: (
    props: Partial<Omit<NavigationDrawerLinkItemProps, 'renderTitle'>>
  ) => React.ReactElement;

  /**
   * Text to be displayed on a badge associated with the link item.
   */
  badgeText?: string;
  /**
   * Number to be displayed on a counter badge associated with the link item.
   */
  badgeNumber?: number;
  /**
   * Determines if the link is in an active state
   * @default false
   */
  isActive?: boolean;
  /**
   * List of link items to be nested
   */
  items?: NavigationDrawerLinkItemProps[];
}

/**
 * Represents a link item within the NavigationDrawer component.
 * This component can display an icon, title, badge text, and badge number.
 */
export const NavigationDrawerLinkItem = ({
  icon,
  title,
  badgeText,
  badgeNumber,
  isActive,
  renderTitle,
  ...rest
}: PropsWithChildren<NavigationDrawerLinkItemProps>) => {
  const { Link } = useEnvironmentContext();
  const { colorMode } = useColorMode();
  const renderIcon = () => (
    <Box
      aria-hidden={true}
      data-testid="navigation-drawer-link-item-icon"
      sx={{
        ...getNavigationDrawerLinkFlexStyles(),
        ...getNavigationDrawerLinkIconStyles(),
      }}
    >
      {icon}
    </Box>
  );

  return (
    <Link sx={getNavigationDrawerLinkStyles(colorMode, isActive)} {...rest}>
      {icon && renderIcon()}
      {(renderTitle && renderTitle({ title })) || (
        <Box sx={getNavigationDrawerLinkContainerStyles()}>
          {title ? title : rest.children}
        </Box>
      )}
    </Link>
  );
};
