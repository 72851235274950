export const ConsumerTierAListTypes = {
  Member: 'MEMBER',
  ALister: 'A_LISTER',
  NewALister: 'NEW_A_LISTER',
  ReturningALister: 'RETURNING_A_LISTER',
} as const;

export type TierAListTypes =
  | 'MEMBER'
  | 'NEW_A_LISTER'
  | 'A_LISTER'
  | 'RETURNING_A_LISTER';

export type UserPlatform = 'website' | 'app';

export interface TierMemberSinceProps {
  format: string;
  __typename: string;
}

export interface TierContentProps {
  title: string;
  message: string;
  showBadge: boolean;
  path: string;
  theme: string;
}

export interface TrackerContentProps {
  title: string;
  message: string;
  progressBackground: string;
  progressColor: string;
  progressTextColor: string;
  titleColor: string;
  iconColor: string;
  messageColor: string;
  theme: string;
  badgeColor: string;
  perksTheme?: string;
}
