import { alleColors } from '../../theme/theme';
import { Maybe } from '@packages/core-alle-graphql-types';

import { TierAListTypes, TrackerContentProps } from './alistTypes';
import { currentYear } from './alistHelpers';
import { formatNumberWithCommas } from '../format';

export const AlistTrackerContent = (
  type: TierAListTypes,
  points: Maybe<number> | undefined,
  tierThreshold: number
): TrackerContentProps => {
  const listStatus = {
    MEMBER: {
      title: `You’ve earned ${points} points this year.`,
      message: `Reach A-List by earning ${formatNumberWithCommas(
        tierThreshold - points!
      )} more points between now and December 31, ${currentYear}.`,
      progressBackground: '#DEDAD7',
      progressColor: alleColors.shellBrown,
      progressTextColor: alleColors.shellBrown,
      titleColor: alleColors.ravenBlack,
      iconColor: alleColors.mediumGray,
      messageColor: alleColors.ravenBlack,
      badgeColor: alleColors.lightSkin,
      theme: `linear-gradient(
         0deg,
         ${alleColors.white}E6,
         ${alleColors.white}E6
       ),
       ${alleColors.nude5};`,
      perksTheme: alleColors.white,
    },
    NEW_A_LISTER: {
      title: 'You’ve reached A-List!',
      message: `You have access to all the best A-List perks through ${
        currentYear + 1
      }.`,
      progressBackground: `${alleColors.white}33`,
      progressColor: 'linear-gradient(to right, #8B6A2B, #F8D98B, #836428)',
      progressTextColor: alleColors.gold,
      titleColor: alleColors.white,
      iconColor: alleColors.neutral,
      messageColor: alleColors.neutral,
      badgeColor: alleColors.neutral,
      theme: alleColors.ravenBlack,
      perksTheme: '#1C1B1B',
    },
    A_LISTER: {
      title: `You’ve earned ${points} points this year.`,
      message: `Keep your A-List status by earning ${formatNumberWithCommas(
        tierThreshold - points!
      )} more points between now and December 31, ${currentYear}.`,
      progressBackground: `${alleColors.white}33`,
      progressColor: 'linear-gradient(to right, #8B6A2B, #F8D98B, #836428)',
      progressTextColor: alleColors.gold,
      titleColor: alleColors.white,
      iconColor: alleColors.neutral,
      messageColor: alleColors.neutral,
      badgeColor: alleColors.neutral,
      theme: alleColors.ravenBlack,
      perksTheme: '#1C1B1B',
    },
    RETURNING_A_LISTER: {
      title: 'Another year on the A-List!',
      message: `Enjoy continued access to all the best A-List perks through ${
        currentYear + 1
      }.`,
      progressBackground: `${alleColors.white}33`,
      progressColor: 'linear-gradient(to right, #8B6A2B, #F8D98B, #836428)',
      progressTextColor: alleColors.gold,
      titleColor: alleColors.white,
      iconColor: alleColors.neutral,
      messageColor: alleColors.neutral,
      badgeColor: alleColors.neutral,
      theme: alleColors.ravenBlack,
      perksTheme: '#1C1B1B',
    },
  };
  return listStatus[type] ?? listStatus.MEMBER;
};
