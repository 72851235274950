import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import styled from 'styled-components';
import { Typography } from '@packages/deprecated-universal-component-library/src/atoms/typography';

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressWrapper = styled.div`
  width: 100%;
  margin-right: 8px;
`;

const LabelWrapper = styled.div`
  min-width: 36px;
`;

const ProgressLine = styled(LinearProgress)<{
  progresscolor?: string;
  progressbackground?: string;
}>`
  min-width: 36px;
  &.MuiLinearProgress-colorPrimary {
    background-color: ${(props) => props.progressbackground};
  }
  .MuiLinearProgress-barColorPrimary {
    background: ${(props) => props.progresscolor};
  }
  &.MuiLinearProgress-root {
    height: 3px;
  }
`;

export interface LinearProgressBarProps {
  value: number;
  textcolor?: string;
  progresscolor?: string;
  progressbackground?: string;
}

export const LinearProgressBar = (props: LinearProgressBarProps) => {
  return (
    <MainWrapper>
      <ProgressWrapper>
        <ProgressLine
          progresscolor={props.progresscolor}
          progressbackground={props.progressbackground}
          variant="determinate"
          value={props.value}
        />
      </ProgressWrapper>
      <LabelWrapper data-testid="linear-progress-label">
        <Typography
          preset={{
            s: 'body-S-regular',
          }}
          css={{
            color: props.textcolor,
          }}
        >
          {props.value}%
        </Typography>
      </LabelWrapper>
    </MainWrapper>
  );
};
