import React from 'react';
import { TierAListTypes } from '@packages/deprecated-universal-component-library/src/helpers/alistTier/alistTypes';

interface SvgProps {
  type: TierAListTypes;
}

export const TrackerIcon = ({ type = 'MEMBER' }: SvgProps) => {
  return type === 'MEMBER' ? (
    <svg
      width="126"
      height="64"
      viewBox="0 0 126 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.297363 26.1368H4.57029V39.3844H6.35732V26.1368H10.6302V24.6764H0.297363V26.1368Z"
        fill="#9A6B5E"
      />
      <path
        d="M22.5862 31.3414H14.862V24.6764H13.0743V39.3844H14.862V32.843H22.5862V39.3844H24.3732V24.6764H22.5862V31.3414Z"
        fill="#9A6B5E"
      />
      <path
        d="M37.0065 37.924H29.8571V32.5958H35.322V31.1354H29.8571V26.1368H36.6368V24.6764H28.0701V39.3844H37.0065V37.924Z"
        fill="#9A6B5E"
      />
      <path
        d="M89.3633 24.6764H87.5763V39.3844H96.081V37.924H89.3633V24.6764Z"
        fill="#9A6B5E"
      />
      <path
        d="M100.456 24.6764H98.6688V39.3838H100.456V24.6764Z"
        fill="#9A6B5E"
      />
      <path
        d="M109.084 30.9503C106.085 30.4977 105.55 29.7574 105.55 28.3173C105.55 26.8771 106.598 25.9314 108.611 25.9314C110.624 25.9314 111.713 26.6927 112.062 28.5442H113.664C113.377 25.9315 111.548 24.492 108.611 24.492C105.838 24.492 103.886 26.2199 103.886 28.4827C103.886 31.0955 105.714 32.0621 108.796 32.535C111.589 32.9876 112.35 33.6867 112.35 35.3531C112.35 37.0195 110.891 38.13 109.001 38.13C106.043 38.13 105.344 36.6487 105.119 34.9005H103.393C103.66 37.41 105.077 39.5701 109.001 39.5701C111.918 39.5701 114.075 37.6984 114.075 35.2295C114.075 32.4526 112.35 31.465 109.083 30.951L109.084 30.9503Z"
        fill="#9A6B5E"
      />
      <path
        d="M115.369 24.6764V26.1368H119.642V39.3844H121.43V26.1368H125.703V24.6764H115.369Z"
        fill="#9A6B5E"
      />
      <path
        d="M57.8486 0L36.2881 64H43.4747L49.5229 45.5609H75.3773L81.5091 64H89.0511L67.8354 0H57.8486ZM51.6234 39.3831L62.4873 6.44853L73.3519 39.3831H51.6234Z"
        fill="#9A6B5E"
      />
    </svg>
  ) : (
    <img
      src="https://media.alle.com/image/upload/v1721664464/alle/wallet-image/rewards-images/A-List/Logo_AList_Progress_ALister.png"
      alt="A-List Progress ALister"
      width="126"
      height="64"
    />
  );
};
