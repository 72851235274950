import React from 'react';
import styled from 'styled-components';

interface SvgProps {
  color?: string;
  width?: string;
  height?: string;
  viewBox?: string;
}
const Svg = styled.svg``;

const ChevronRight = ({
  color = '#fff',
  width,
  height,
  viewBox = '0 0 24 24',
}: SvgProps) => {
  return (
    <Svg width={width} height={height} viewBox={viewBox}>
      <path
        fill={color}
        d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"
      />
    </Svg>
  );
};

export { ChevronRight };
