import React from 'react';
import styled from 'styled-components';

import { Typography } from '@packages/deprecated-universal-component-library/src/atoms/typography';
import { ChevronRight } from '@packages/deprecated-component-library/src/icons/chevronRight';
import { Link } from '@packages/deprecated-component-library/src/core/link';
import { above } from '@packages/deprecated-universal-component-library/src/utils/mediaTemplate';
import { AlistPerksContent } from '@packages/deprecated-universal-component-library/src/helpers/alistTier/alistTierContent';

const FooterWrapper = styled.div`
  padding: 0 24px;

  ${above.mobile`
   display: flex;
   justify-content: center;
   align-items: center;
 `}

  ${above.tablet`
   display: block;
 `}
`;

const FooterContent = styled.div`
  width: 100%;
  ${above.mobile`
   width: 362px;
 `}
  ${above.tablet`
   width: 100%;
 `}
`;

const ContentWrapper = styled.span``;

const AlistTrackerFooter = ({ isAlister }: { isAlister: boolean }) => {
  const textColor = isAlister
    ? AlistPerksContent.alistTextColor
    : AlistPerksContent.textColor;
  const borderColor = isAlister ? '#FFFFFF14' : '#09090914';
  const chevronColor = isAlister ? '#FFFFFF' : '#090909';
  return (
    <FooterWrapper>
      <FooterContent>
        <Typography
          data-testid={'perks-title'}
          preset={{
            s: 'headline-S-petersburg',
          }}
          css={{ color: textColor, padding: '24px 0' }}
        >
          Helpful links
        </Typography>

        <Link
          to={'https://alle.com/article/a-list-benefits'}
          external={true}
          target={'_blank'}
        >
          <Typography
            data-testid={'perks-title'}
            preset={{
              s: 'body-M-regular',
            }}
            css={{
              color: textColor,
              borderTop: `1px solid ${borderColor}`,
              borderBottom: `1px solid ${borderColor}`,
              padding: '24px 16px 24px 0',
              marginBottom: '24px',
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '16px',
            }}
          >
            <ContentWrapper>How does A-List work?</ContentWrapper>
            <ContentWrapper>
              <ChevronRight
                width={'16px'}
                height={'8px'}
                color={chevronColor}
              />
            </ContentWrapper>
          </Typography>
        </Link>
      </FooterContent>
    </FooterWrapper>
  );
};

export { AlistTrackerFooter };
