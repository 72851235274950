import { Maybe } from '@packages/core-alle-graphql-types';

export const pointsToPercentage = (
  points: Maybe<number> | undefined,
  tierThreshold?: number | null
) => {
  if (!points || points <= 0) return 0;
  const threshold = tierThreshold ?? 1200;
  const tierPoints = points && points > threshold ? threshold : points;
  return Math.trunc((tierPoints / threshold) * 100);
};
