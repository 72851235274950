import React from 'react';
import styled, { css } from 'styled-components';

import { Typography } from '@packages/deprecated-universal-component-library/src/atoms/typography';
import { AlistPerksContent } from '@packages/deprecated-universal-component-library/src/helpers/alistTier/alistTierContent';
import { CheckSolidCircleIcon } from '@allergan-data-labs/alle-elements-icons/24/checkSolidCircleIcon';
import { CheckOutlineCircleIcon } from '@allergan-data-labs/alle-elements-icons/24/checkOutlineCircleIcon';
import { LockClosedOutlineIcon } from '@allergan-data-labs/alle-elements-icons/24/lockClosedOutlineIcon';
import { CircleCheckGold } from '@packages/deprecated-component-library/src/icons/circleCheckGold';
import { Link } from '@packages/deprecated-component-library/src/core/link';

const PerksWrapper = styled.div`
  display: block;
  margin-top: 24px;
`;

const PerksList = styled.div`
  max-width: 500px;
  padding: 0 24px 24px 24px;
`;

const ListIcon = styled.span`
  margin-right: 16px;
`;

const ListContent = styled.div`
  display: flex;
`;

const Title = styled(Typography)<{ isAlister: boolean; titleColor: string }>`
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 18px;
  ${(props) =>
    props.isAlister
      ? css`
          background-image: ${props.titleColor};
          color: transparent;
          background-clip: text;
          width: 50%;
        `
      : css`
          color: ${props.titleColor};
        `}
`;

export const AlistPerks = ({
  isAlister,
  isRewardsHubEnabled,
}: {
  isAlister: boolean;
  isRewardsHubEnabled: boolean;
}) => {
  const perks = isAlister
    ? isRewardsHubEnabled
      ? AlistPerksContent.alistPerksAList
      : AlistPerksContent.alistPerksAListPointsBankingDisabled
    : isRewardsHubEnabled
    ? AlistPerksContent.perks
    : AlistPerksContent.perksPointsBankingDisabled;
  const secondPerks = isAlister
    ? isRewardsHubEnabled
      ? AlistPerksContent.perks
      : AlistPerksContent.perksPointsBankingDisabled
    : isRewardsHubEnabled
    ? AlistPerksContent.alistPerksMember
    : AlistPerksContent.alistPerksMemberPointsBankingDisabled;
  const title = isAlister
    ? AlistPerksContent.alistTitle
    : AlistPerksContent.title;
  const secondTitle = isAlister
    ? AlistPerksContent.secondAlistTitle
    : AlistPerksContent.secondTitle;
  const textColor = isAlister
    ? AlistPerksContent.alistTextColor
    : AlistPerksContent.textColor;
  const titleColor = isAlister
    ? AlistPerksContent.titleColor
    : AlistPerksContent.textColor;

  const renderPerk = (perk: string) => {
    if (perk.includes('VIP Member support') && isAlister) {
      const parts = perk.split(' at ');
      return (
        <span>
          {parts[0]} at{' '}
          <Link
            to="tel:1-888-281-1849"
            external
            style={{ textDecoration: 'underline', color: 'white' }}
          >
            1.888.281.1849
          </Link>
        </span>
      );
    }
    return <span>{perk}</span>;
  };

  return (
    <PerksWrapper>
      <PerksList>
        <Title
          data-testid={'perks-title'}
          preset={{
            s: 'headline-S-graphik',
          }}
          isAlister={isAlister}
          titleColor={titleColor}
        >
          {title}
        </Title>

        {perks.map((perk, index) => (
          <ListContent key={`perk-${index}`}>
            <ListIcon>
              {isAlister ? (
                <CircleCheckGold />
              ) : (
                <CheckSolidCircleIcon sx={{ color: '#9A6B5E' }} />
              )}
            </ListIcon>
            <Typography
              data-testid={`perk-list`}
              preset={{
                s: 'body-L-regular',
              }}
              css={{
                marginBottom: '16px',
                display: 'inline',
                color: textColor,
              }}
            >
              {renderPerk(perk)}
            </Typography>
          </ListContent>
        ))}
      </PerksList>
      <PerksList>
        <Typography
          preset={{
            s: 'headline-S-graphik',
          }}
          css={{
            marginBottom: '24px',
            color: textColor,
            fontWeight: 500,
            fontSize: 18,
          }}
        >
          {secondTitle}
        </Typography>

        {secondPerks.map((perk, index) => (
          <ListContent key={`perk-${index}`}>
            <ListIcon>
              {isAlister ? (
                <CheckOutlineCircleIcon sx={{ color: '#B3B0AE' }} />
              ) : (
                <LockClosedOutlineIcon sx={{ color: '#B3B0AE' }} />
              )}
            </ListIcon>
            <Typography
              preset={{
                s: 'body-L-regular',
              }}
              css={{
                marginBottom: '16px',
                display: 'inline',
                color: textColor,
              }}
            >
              {renderPerk(perk)}
            </Typography>
          </ListContent>
        ))}
      </PerksList>
    </PerksWrapper>
  );
};
