import { alleColors } from '../../theme/theme';

import { TierAListTypes } from './alistTypes';
import { currentYear } from './alistHelpers';
import { formatNumberWithCommas } from '../format';
export const gradients = {
  gold: `background: linear-gradient(
    73.47deg,
    #836428 -19.07%,
    #F8D98B 51.7%,
    #836428 93.03%
  ),
  linear-gradient(
    0deg,
    ${alleColors.absoluteBlack}40,
    ${alleColors.absoluteBlack}40
  ),
  ${alleColors.nude5};
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;`,
  nude: `color: ${alleColors.nude5};`,
};

export const AlistPerksContent = {
  title: 'Your Allē member perks',
  alistTitle: 'Your A-List perks',
  secondTitle: 'A-List perks',
  secondAlistTitle: 'Always-on Allē member perks',
  textColor: alleColors.ravenBlack,
  alistTextColor: alleColors.white,
  titleColor: 'linear-gradient(to top right, #836428, #F8D98B, #836428)',
  perks: [
    'Exchange your points for one Allē product a year',
    'Welcome offer via Allē Flash',
    'Annual savings events',
    'Point multiplier events',
  ],
  perksPointsBankingDisabled: [
    'Redeem points for dollar-off savings',
    'Welcome offer via Allē Flash',
    'Annual savings events',
    'Point multiplier events',
  ],
  alistPerksMember: [
    'Points don’t expire while you’re an A-Lister',
    'Exchange points for one additional Allē product a year',
    'Redeem points for dollar-off savings',
    'Exclusive offers and surprise gifts',
    "Get access to the A-Line, Allē's VIP Member support",
    'Be the first to know about offers and events',
  ],
  alistPerksMemberPointsBankingDisabled: [
    'Points don’t expire while you’re an A-Lister',
    'Exclusive offers and surprise gifts',
    "Get access to the A-Line, Allē's VIP Member support",
    'Be the first to know about offers and events',
  ],
  alistPerksAList: [
    'Points don’t expire while you’re an A-Lister',
    'Exchange points for two Allē products a year',
    'Redeem points for dollar-off savings',
    'Exclusive offers and surprise gifts',
    "Get access to the A-Line, Allē's VIP Member support",
    'Be the first to know about offers and events',
  ],
  alistPerksAListPointsBankingDisabled: [
    'Points don’t expire while you’re an A-Lister',
    'Exclusive offers and surprise gifts',
    "Get access to the A-Line, Allē's VIP Member support",
    'Be the first to know about offers and events',
  ],
};

export const AlistTierContent = (
  type: TierAListTypes,
  tierThreshold?: number | null
) => {
  const listStatus = {
    MEMBER: {
      title: 'A-List progress',
      message:
        'Unlock VIP benefits like early access, surprise gifts, and more.',
      showBadge: false,
      path: `${alleColors.nude5}`,
      theme: gradients.nude,
      trail: `${alleColors.nude5}33`,
    },
    NEW_A_LISTER: {
      title: 'Congrats! You’re on the A-List.',
      message: `You have access to all the best A-List perks through ${
        currentYear + 1
      }.`,
      showBadge: true,
      path: '#F8D98B',
      theme: gradients.gold,
    },
    A_LISTER: {
      title: `${currentYear} A-List progress`,
      message: `Earn ${formatNumberWithCommas(
        tierThreshold ?? 1200
      )} points this calendar year to keep your A-List status.`,
      showBadge: true,
      path: '#F8D98B',
      theme: gradients.gold,
    },
    RETURNING_A_LISTER: {
      title: 'Another year on the A-List!',
      message: `Enjoy continued access to all the best A-List perks through ${
        currentYear + 1
      }.`,
      showBadge: true,
      path: '#F8D98B',
      theme: gradients.gold,
    },
  };
  return listStatus[type] ?? listStatus.MEMBER;
};
