import React, { SVGProps } from 'react';
import { IconContainer } from './iconContainer';

const CircleCheckGold = (props: SVGProps<SVGSVGElement>) => {
  return (
    <IconContainer width="24px" height="24px">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.5376 16.2221L17.9212 8.83844L16.8606 7.77778L10.0072 14.6311L6.81134 11.4352L5.75068 12.4959L9.47692 16.2221L10.0072 16.7524L10.5376 16.2221Z"
          fill="url(#paint0_linear_18666_2232)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_18666_2232"
            x1="-0.5"
            y1="18"
            x2="49.3531"
            y2="8.54779"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.046875" stopColor="#836428" />
            <stop offset="0.635417" stopColor="#F8D98B" />
            <stop offset="0.979167" stopColor="#836428" />
          </linearGradient>
        </defs>
      </svg>
    </IconContainer>
  );
};

export { CircleCheckGold };
