import React from 'react';
import styled from 'styled-components';

import { alleColors } from '@packages/deprecated-component-library/src/colorPalette';
import {
  LineSkeleton,
  LineSkeletonProps,
} from '@packages/deprecated-component-library/src/loading/lineSkeleton';

const SkeletonWrapper = styled.div<{ showborder?: boolean }>`
  border-top: ${(props) =>
    props.showborder ? `1px solid ${alleColors.gray[600]}` : 'none'};
  margin: 16px 0;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AlistSkeletonLoader = ({ backgroundColor }: LineSkeletonProps) => {
  return (
    <SkeletonWrapper showborder>
      <LineSkeleton
        backgroundColor={backgroundColor}
        minHeight={12}
        minWidth={173}
        style={{ borderRadius: '2px' }}
      />
      <LineSkeleton
        backgroundColor={backgroundColor}
        minHeight={12}
        minWidth={265}
        style={{ borderRadius: '2px' }}
      />
    </SkeletonWrapper>
  );
};

export const AlistTrackerSkeletonLoader = ({
  backgroundColor,
}: LineSkeletonProps) => {
  return (
    <SkeletonWrapper>
      <LineSkeleton
        backgroundColor={backgroundColor}
        minHeight={12}
        minWidth={295}
        style={{ borderRadius: '2px' }}
      />
      <LineSkeleton
        backgroundColor={backgroundColor}
        minHeight={12}
        minWidth={295}
        style={{ borderRadius: '2px' }}
      />
      <LineSkeleton
        backgroundColor={backgroundColor}
        minHeight={12}
        minWidth={295}
        style={{ borderRadius: '2px' }}
      />
    </SkeletonWrapper>
  );
};
