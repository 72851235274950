import { Maybe } from '@packages/core-alle-graphql-types';

import { TierAListTypes, UserPlatform } from './alistTypes';

export const AlistTierSegmentMetadata = (
  type: TierAListTypes,
  authState: string,
  id: Maybe<string> | undefined,
  platform: UserPlatform,
  component: 'Wallet' | 'Profile'
) => {
  const listSegment = {
    MEMBER: {
      event: {
        action_source: platform,
        activator: 'A-list Progress',
        explainer: `Member clicks to view A-list progress on ${component}`,
        invoked_by: 'consumer',
      },
      user: {
        id,
        status: authState,
        type: 'consumer',
      },
    },
    NEW_A_LISTER: {
      event: {
        action_source: platform,
        activator: 'A-list Reached',
        explainer: `Member clicks to view A-list status info on ${component}`,
        invoked_by: 'consumer',
      },
      user: {
        id,
        status: authState,
        type: 'consumer',
      },
    },
    A_LISTER: {
      event: {
        action_source: platform,
        activator: 'Keep A-list',
        explainer: `Member clicks to view A-list status info on ${component}`,
        invoked_by: 'consumer',
      },
      user: {
        id,
        status: authState,
        type: 'consumer',
      },
    },
    RETURNING_A_LISTER: {
      event: {
        action_source: platform,
        activator: 'A-list Maintained',
        explainer: `Member clicks to view A-list status info on ${component}`,
        invoked_by: 'consumer',
      },
      user: {
        id,
        status: authState,
        type: 'consumer',
      },
    },
  };
  return listSegment[type] ?? listSegment.MEMBER;
};
