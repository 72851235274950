import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { logger } from '@packages/core-datadog-logging/src/logger';
import { ConsumerProfile, Query } from '@packages/core-alle-graphql-types';
import { identifyTraits } from './identifyTraits';

const QUERY_PROFILE = gql`
  query SyncSegmentIdentityTraits {
    viewer {
      id
      profile {
        __typename
        ... on ConsumerProfile {
          id
          internalId
          firstName
          lastName
          emailAddress
          phoneNumber
          pendingPhoneNumber
          address {
            zipCode
          }
          gender
          birthDate {
            format(layout: "MM.DD.YYYY")
          }
          communicationPreferences {
            optInMarketingEmail
            optInMarketingSMS
          }
        }
      }
    }
  }
`;

const useSyncSegmentIdentity = () => {
  const { refetch } = useQuery<Query>(QUERY_PROFILE, {
    fetchPolicy: 'cache-first',
    skip: true,
  });

  return React.useCallback(
    (callback?: () => void) => {
      refetch()
        .then((res) => {
          if (res.data?.viewer) {
            const consumerProfile = res.data.viewer.profile as ConsumerProfile;
            const internalId = consumerProfile.internalId;

            const traits = {
              alle_accepted_promotions_email:
                consumerProfile.communicationPreferences?.optInMarketingEmail,
              alle_accepted_sms:
                consumerProfile.communicationPreferences?.optInMarketingSMS,
              alle_birthdate: consumerProfile.birthDate?.format,
              alle_email: consumerProfile.emailAddress,
              alle_first_name: consumerProfile.firstName,
              alle_gender: consumerProfile.gender,
              alle_last_name: consumerProfile.lastName,
              alle_phone: consumerProfile.phoneNumber,
              alle_zip_code: consumerProfile.address?.zipCode,
            };

            if (consumerProfile && internalId) {
              identifyTraits(
                traits,
                {
                  ...(internalId ? { alleId: internalId } : {}),
                  ...(consumerProfile.phoneNumber
                    ? { phone: consumerProfile.phoneNumber }
                    : {}),
                },
                undefined,
                callback
              );
            }
          } else {
            logger.error('useSyncSegmentIdentity-error', {
              error: {
                friendlyMessage: 'Profile data is not available',
              },
            });
          }
        })
        .catch((error) => {
          logger.error('useSyncSegmentIdentity-error', {
            error,
          });
        });
    },
    [refetch]
  );
};

export { useSyncSegmentIdentity };
