import { useCallback } from 'react';
import { SiteNavigated } from '@packages/core-segment-tracking/src/generated';
import { useAuth } from '../../auth/AuthProvider';
import { AuthState } from '../../auth/authMachine';
import { useSegment } from '@packages/core-segment-tracking/src/segmentContext';
import { Routes } from '../../constants/routes';

const useHeaderTrackingEvents = () => {
  const { trackConsumer } = useSegment();
  const { authState } = useAuth();
  const isLoggedIn = authState.matches(AuthState.isLoggedIn);

  const trackAlleLogoClick = useCallback(() => {
    trackConsumer()?.siteNavigated({
      event: {
        action_source: 'consumer web',
        activator: isLoggedIn
          ? 'Logged In - Allē Logo'
          : 'Logged Out - Allē Logo',
        explainer: 'Tracking user engagement with top navigation',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: isLoggedIn ? 'logged_in' : 'logged_out',
        event_type: 'engagement',
      },
      site_type: 'consumer web',
      navigation_type: 'top navigation',
      event_source_url: `${window.location.href}`,
      text: 'Allē',
      url: `${Routes.accountDashboard}`,
    });
  }, [trackConsumer, isLoggedIn]);

  const trackLoginLinkClick = useCallback(() => {
    const linkClickSegment: SiteNavigated = {
      event: {
        action_source: 'consumer web',
        activator: 'Log in Button',
        explainer: 'Tracking user engagement with top navigation',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: 'logged_out',
        event_type: 'engagement',
      },
      site_type: 'consumer web',
      navigation_type: 'top navigation',
      event_source_url: `${window.location.href}`,
      text: 'Log in',
      url: `https://alle.com/login`,
    };

    trackConsumer()?.siteNavigated(linkClickSegment);
  }, [trackConsumer]);

  const trackJoinAlleClick = useCallback(() => {
    const linkClickSegment: SiteNavigated = {
      event: {
        action_source: 'consumer web',
        activator: 'Join Allē Button',
        explainer: 'Tracking user engagement with top navigation',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: 'logged_out',
        event_type: 'engagement',
      },
      site_type: 'consumer web',
      navigation_type: 'top navigation',
      event_source_url: `${window.location.href}`,
      text: 'Join Allē',
      url: `https://alle.com/registration`,
    };

    trackConsumer()?.siteNavigated(linkClickSegment);
  }, [trackConsumer]);

  const trackProfileAvatarClick = useCallback(() => {
    trackConsumer()?.elementEngaged({
      action: 'Open',
      event: {
        activator: 'Top Nav',
        explainer: 'Tracking user engagement with top navigation',
        extra_data: {
          properties: 'Hamburger Menu',
        },
        invoked_by: 'consumer',
        // @ts-ignore
        type: 'consumer',
      },
    });
  }, [trackConsumer]);

  const trackHamburgerMenuClick = useCallback(() => {
    trackConsumer()?.elementEngaged({
      action: 'Open',
      event: {
        activator: 'Top Nav',
        explainer: 'Tracking user engagement with top navigation',
        extra_data: {
          properties: 'Hamburger Menu',
        },
        invoked_by: 'consumer',
        // @ts-ignore
        type: 'consumer',
      },
    });
  }, [trackConsumer]);

  return {
    trackAlleLogoClick,
    trackLoginLinkClick,
    trackJoinAlleClick,
    trackProfileAvatarClick,
    trackHamburgerMenuClick,
  };
};

export { useHeaderTrackingEvents };
