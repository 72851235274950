import React from 'react';
import ContentLoader from 'react-content-loader';

export interface LineSkeletonProps {
  backgroundColor?: string;
  foregroundColor?: string;
  minWidth?: number;
  minHeight?: number;
  fullWith?: boolean;
  preserveAspectRatio?: string;
  x?: number;
  y?: number;
  style?: React.CSSProperties;
  className?: string;
}

const LineSkeleton = ({
  minHeight = 12,
  minWidth = 100,
  backgroundColor,
  foregroundColor,
  fullWith,
  preserveAspectRatio = 'none',
  x = 0,
  y = 0,
  style,
  className,
}: LineSkeletonProps) => (
  <ContentLoader
    speed={2}
    width={minWidth}
    height={minHeight}
    viewBox={`0 0 ${minWidth} ${minHeight}`}
    backgroundColor={backgroundColor ?? '#f3f3f3'}
    foregroundColor={foregroundColor ?? '#ecebeb'}
    preserveAspectRatio={preserveAspectRatio}
    style={fullWith ? { width: '100%', ...style } : style}
    className={className}
  >
    <rect x={x} y={y} rx="0" ry="0" width={minWidth} height={minHeight} />
  </ContentLoader>
);

export { LineSkeleton };
