import React from 'react';
import styled from 'styled-components';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';

const Container = styled.div``;

interface BuildStylesProps {
  rotation?: number; // Number of turns, 0-1
  strokeLinecap?: number | string;
  textColor?: string;
  textSize?: string | number;
  pathColor?: string;
  pathTransition?: string;
  pathTransitionDuration?: number; // Measured in seconds
  trailColor?: string;
  backgroundColor?: string;
}
export interface CircularProgressBarProps {
  children?: React.ReactNode;
  containerStyles?: React.CSSProperties;
  value: number; // Mandatory property
  circularRation?: number;
  strokeWidth?: number;
  _buildStyles?: BuildStylesProps;
  background?: boolean;
  backgroundPadding?: number;
  circleRatio?: number;
  classes?: {
    root: string;
    trail: string;
    path: string;
    text: string;
    background: string;
  };
  className?: string;
  counterClockwise?: boolean;
  maxValue?: number;
  minValue?: number;
  text?: string;
}

export const CircularProgressBar = ({
  children,
  containerStyles,
  _buildStyles,
  value,
  circleRatio,
  strokeWidth,
  background,
}: CircularProgressBarProps) => (
  <Container style={{ ...containerStyles }}>
    <CircularProgressbarWithChildren
      value={value}
      circleRatio={circleRatio}
      strokeWidth={strokeWidth}
      background={background}
      styles={buildStyles({ ..._buildStyles })}
    >
      {children}
    </CircularProgressbarWithChildren>
  </Container>
);
