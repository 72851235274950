import { Birthday, Month } from '@packages/core-alle-graphql-types';

const phoneNumberRegex = /^\([2-9][0-9]{2}\) [0-9]{3}-[0-9]{4}$/;
const phoneNumberwithCountryCodeRegex =
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
const simplePhoneNumberRegex = /^[2-9]\d{9}$/;
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const zipCodeRegex = /^\d{5}$/;
const urlRegex = /^((https?|ftp):\/\/)?[^\s/$.?#].[^\s]*$/;

/**
 * According to NANP (North American Numbering Plan) standards a valid phone number can not be:
 * #11-###-####
 * #9#-###-####
 * 37#-###-####
 * 96#-###-####
 * ###-1##-####
 * ###-#11-####
 * ###-988-####
 *
 * Confluence doc with more details: https://adl-technology.atlassian.net/l/c/L4EF958n
 */
const phoneNumberNANPRegex =
  /^(\((?!(37|96))[2-9](?!11)[0-8][0-9]\) )[2-9](?!11)[0-9]{2}-[0-9]{4}$/;
const simplePhoneNumberNANPRegex =
  /^(?!(37|96))([2-9](?!11)[0-8][0-9])[2-9](?!11)[0-9]{2}[0-9]{4}$/;

const daysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

/**
 * Converts the Birthday object to string form for display.
 *
 * @param birthday
 * @returns String in the format of MM.DD. Returns null if birthday object is not a valid birthday.
 */
const birthdayObjectToText = (birthday: Birthday): string | null => {
  const numericMonth = Object.values(Month).indexOf(birthday.month) + 1;

  if (numericMonth === 0) return null;
  if (birthday.day < 1 || birthday.day > daysInMonth[numericMonth - 1]) {
    return null;
  }

  const month = numericMonth.toString().padStart(2, '0');
  const day = birthday.day.toString().padStart(2, '0');

  return `${month}.${day}`;
};

/**
 * @param birthday A string to be in format MM.DD
 */
const birthdayTextToObject = (birthday: string): Birthday | null => {
  if (!/^\d{2}.\d{2}$/.test(birthday)) return null;

  const numericMonth = parseInt(birthday.split('.')[0], 10);
  if (numericMonth < 1 || numericMonth > 12) return null;

  const monthKey = Object.keys(Month)[numericMonth - 1];
  const month = Month[monthKey as keyof typeof Month];
  const day = parseInt(birthday.split('.')[1], 10);

  if (day < 1 || day > daysInMonth[numericMonth - 1]) return null;

  return {
    day,
    month,
  };
};

const isValidEmail = (value: string) => {
  return emailRegex.test(value.toLowerCase());
};

const isValidUrl = (value: string) => {
  return urlRegex.test(value.toLowerCase());
};

const isValidZipCode = (value: string) => {
  return zipCodeRegex.test(value);
};

const isValidPhoneNumberwithCountryCode = (value: string) => {
  return phoneNumberwithCountryCodeRegex.test(value);
};

const isValidPhoneNumber = (value: string): boolean => {
  // Include the simple phone number for inputs that get autofilled by
  // browsers. The text mask will eventually update to be formatted
  // but accepting here as well will prevent showing error messages.
  return phoneNumberRegex.test(value) || simplePhoneNumberRegex.test(value);
};

const isValidNANPPhoneNumber = (value: string): boolean => {
  // Include the simple phone number for inputs that get autofilled by
  // browsers. The text mask will eventually update to be formatted
  // but accepting here as well will prevent showing error messages.
  return (
    phoneNumberNANPRegex.test(value) || simplePhoneNumberNANPRegex.test(value)
  );
};

const formatNumberWithCommas = (num?: number | string): string => {
  if (num === undefined) return '';
  if (!num && typeof num !== 'number') return '';

  if (typeof num !== 'string') {
    num = num.toString();
  }

  return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

/**
 * Converts a string with commas to a number
 *
 * @param num A string with commas
 * @example '$2,150.89'
 * @returns A number
 * @example 2150.89
 */
export const numberWithCommasToNumber = (num?: string): number | null => {
  if (!num) return null;
  if (num[0] === '$') {
    num = num.slice(1);
  }

  return parseFloat(num.replace(/,/g, ''));
};

const stripNonDigits = (value: string) => value.replace(/\D/g, '');

const formatPhoneNumberValue = (value: string): string => {
  const cleaned = stripNonDigits(value);
  const match = cleaned.match(/^(\d{1,2})?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? `+${match[1]} ` : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return '';
};

const pluralize = (word: string, quantity: number) => {
  if (quantity === 1) {
    return word;
  }
  return word + 's';
};

const capitalize = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

interface DollarValueInput {
  quantity: number;
  unit: string;
}
const promotionValue = ({ quantity, unit }: DollarValueInput): string => {
  const lowerCaseUnit = unit.toLowerCase();
  switch (lowerCaseUnit) {
    case 'dollar':
    case 'dollars':
      return `$${quantity}`;
    case 'cent':
    case 'cents':
      return `$${quantity / 100}`;
    case 'unit':
    case 'units':
      return `${quantity} ${quantity > 1 ? 'units' : 'unit'}`;
    case 'syringe':
    case 'syringes':
      return `${quantity} ${quantity > 1 ? 'syringes' : 'syringe'}`;
    case 'product':
    case 'products':
      return `${quantity} ${quantity > 1 ? 'products' : 'product'}`;
    case 'ml':
      return `${quantity} mL`;
    default:
      console.error(`Missing Promotion Value conversion for unit: ${unit}`, {
        tags: {
          action: 'Formatting promotion value',
          businessFunction: 'promotion',
          error: `Missing Promotion Value conversion for unit: ${unit}`,
          location: window.location.href,
        },
      });
      return `${quantity} ${unit}`;
  }
};

const dollarValue = ({
  quantity,
  unit,
}: {
  quantity: number;
  unit: string;
}): number => {
  const lowerCaseUnit = unit.toLowerCase();
  switch (lowerCaseUnit) {
    case 'dollar':
    case 'dollars':
      return quantity;
    case 'cent':
    case 'cents':
      return quantity / 100;
    default:
      throw new Error(`Missing dollarValue conversion for unit: ${unit}`);
  }
};

const timeConvert = (time: string) => {
  const time_part_array = time.split(':');
  let ampm = 'AM';
  if (Number(time_part_array[0]) >= 12) {
    ampm = 'PM';
  }
  if (Number(time_part_array[0]) > 12) {
    time_part_array[0] = String(Number(time_part_array[0]) - 12);
  }

  if (Number(time_part_array[0]) === 0) {
    time_part_array[0] = '12';
  }

  const formatted_time =
    String(parseInt(time_part_array[0], 10)) + ':' + time_part_array[1] + ampm;
  return formatted_time;
};

const formatUrl = (uri: string): string => {
  return uri.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
};

function convertToMonthDay(inputString?: string): string {
  if (!inputString) return '';
  try {
    // Assuming the input string is in the format "MM.DD.YYYY"
    const [month, day, year] = inputString.split('.').map(Number);
    const dateObject = new Date(year, month - 1, day);

    // Check if the date is valid
    if (isNaN(dateObject.getTime())) {
      throw new Error('Invalid date');
    }

    // Convert the date to the desired format "Mon DD"
    const options = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    } as Intl.DateTimeFormatOptions;
    const resultString = dateObject.toLocaleDateString('en-US', options);

    return resultString;
  } catch (error) {
    return 'Invalid date format';
  }
}

function convertDate(day: number, month: number, year: number): string {
  try {
    const dateObject = new Date(year, month - 1, day);

    // Check if the date is valid
    if (isNaN(dateObject.getTime())) {
      throw new Error('Invalid date');
    }

    // Convert the date to the desired format "Mon DD"
    const options = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    } as Intl.DateTimeFormatOptions;
    const resultString = dateObject.toLocaleDateString('en-US', options);

    return resultString;
  } catch (error) {
    return 'Invalid date format';
  }
}

function convertStringDate(dateString: string): Date {
  //Assuming the date string is like Jul 21, 2024
  // Result will be ['jul', '21', '2024']
  const dateSplit = dateString.toLowerCase().replace(',', '').split(' ');
  const monthsToIndex: { [key: string]: number } = {
    jan: 0,
    feb: 1,
    mar: 2,
    apr: 3,
    may: 4,
    jun: 5,
    jul: 6,
    aug: 7,
    sep: 8,
    oct: 9,
    nov: 10,
    dec: 11,
  };
  return new Date(
    Number(dateSplit[2]), // Year
    Number(dateSplit[1]), // Month
    monthsToIndex[dateSplit[0]] // Day
  );
}

export {
  birthdayObjectToText,
  birthdayTextToObject,
  capitalize,
  dollarValue,
  promotionValue,
  formatNumberWithCommas,
  formatPhoneNumberValue,
  isValidNANPPhoneNumber,
  isValidPhoneNumberwithCountryCode,
  isValidPhoneNumber,
  isValidEmail,
  isValidUrl,
  isValidZipCode,
  pluralize,
  stripNonDigits,
  timeConvert,
  formatUrl,
  convertToMonthDay,
  convertDate,
  convertStringDate,
};
