import jstz from 'jstimezonedetect';

const Timezones = {
  AMERICA: {
    ANCHORAGE: 'America/Anchorage',
    CHICAGO: 'America/Chicago',
    DENVER: 'America/Denver',
    LOS_ANGELES: 'America/Los_Angeles',
    NEW_YORK: 'America/New_York',
    PHOENIX: 'America/Phoenix',
    PUERTO_RICO: 'America/Puerto_Rico',
  },
  PACIFIC: {
    HONOLULU: 'Pacific/Honolulu',
  },
};

// Order matters here. This is the order timezones will
// appear in the dropdown menu (product requirement).
const usTimezones = [
  Timezones.PACIFIC.HONOLULU,
  Timezones.AMERICA.ANCHORAGE,
  Timezones.AMERICA.LOS_ANGELES,
  Timezones.AMERICA.DENVER,
  Timezones.AMERICA.PHOENIX,
  Timezones.AMERICA.CHICAGO,
  Timezones.AMERICA.NEW_YORK,
  Timezones.AMERICA.PUERTO_RICO,
];

const getTimezoneLabel = (value: string) => {
  const timezones: { [key: string]: string } = {
    [Timezones.AMERICA.ANCHORAGE]: 'Alaska Time Zone',
    [Timezones.AMERICA.CHICAGO]: 'Central Time Zone',
    [Timezones.AMERICA.DENVER]: 'Mountain Time Zone',
    [Timezones.AMERICA.LOS_ANGELES]: 'Pacific Time Zone',
    [Timezones.AMERICA.NEW_YORK]: 'Eastern Time Zone',
    [Timezones.AMERICA.PHOENIX]: 'Mountain Time Zone (Phoenix)',
    [Timezones.AMERICA.PUERTO_RICO]: 'Atlantic Time Zone (Puerto Rico)',
    [Timezones.PACIFIC.HONOLULU]: 'Hawaii-Aleutian Time Zone',
  };
  return timezones[value];
};

const getTimezoneAbbreviation = (value: string) => {
  const timezones: { [key: string]: string } = {
    [Timezones.AMERICA.ANCHORAGE]: 'AK',
    [Timezones.AMERICA.CHICAGO]: 'CT',
    [Timezones.AMERICA.DENVER]: 'MT',
    [Timezones.AMERICA.LOS_ANGELES]: 'PT',
    [Timezones.AMERICA.NEW_YORK]: 'ET',
    [Timezones.AMERICA.PHOENIX]: 'MST',
    [Timezones.AMERICA.PUERTO_RICO]: 'AST',
    [Timezones.PACIFIC.HONOLULU]: 'HAST',
  };
  return timezones[value];
};

const TimezoneOptions = usTimezones.map((tz) => ({
  value: tz,
  label: getTimezoneLabel(tz),
}));

const getTimezone = () => {
  try {
    // uses Intl API when available, has back-up logic when not available
    const intlName = jstz.determine().name();
    if (intlName && intlName.length > 3) {
      return intlName;
    }
    return Timezones.AMERICA.LOS_ANGELES;
  } catch (e) {
    return Timezones.AMERICA.LOS_ANGELES;
  }
};

const getUsTimezone = () => {
  const timezone = jstz.determine().name();

  if (usTimezones.includes(timezone)) {
    return timezone;
  }

  return Timezones.AMERICA.CHICAGO;
};

export {
  Timezones,
  getTimezone,
  getUsTimezone,
  getTimezoneLabel,
  getTimezoneAbbreviation,
  TimezoneOptions,
};
